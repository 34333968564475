import React from "react"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const TermsConditions = () => {
  return (
    <div id="main-wrapper">
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h1 className="breadcrumb-title">Terms & Conditions</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-wrapper-reveal">
        {/* <!--====================  Conact us Section Start ====================--> */}
        <div className="contact-us-section-wrappaer section-space--pt_40 section-space--pb_70">
          <div className="section-title-wrap section-space--mb_60">
            <div className="container">
              <div className="row align-items-left">
                <div className="col-lg-6 col-lg-6"></div>
                <b style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                  Please read these Terms and Conditions carefully as they will
                  form a contract between us and you; Your acceptance of which
                  is agreed from the moment you sign our Software Development
                  Agreement. We have tried to keep these Terms and Conditions
                  brief, easy to understand, and as straight forward as
                  possible. If you have any questions, however, please do not
                  hesitate to contact us.
                </b>
                <b style={{ paddingBottom: "20px" }}>
                  TERMINOLOGY
                  <br />
                  You (‘The Client’) Us (Aamanto Technologies, and hereinafter
                  referred to as ‘the Company’) The Project the body of work
                  that is being undertaken. WHAT DO BOTH PARTIES AGREE TO?
                </b>
                <ul>
                  <h3>THE CLIENT AGREES TO</h3>
                </ul>
                <ul className="dots">
                  <li>
                    Provide the Company, within a reasonable timescale,
                    everything that is requested from You to complete the
                    Project including Text, images and other information.
                  </li>
                  <li>
                    Provide the Company with text and images in the format as
                    stated below (see Photographs and images)
                  </li>
                  <li>
                    Review the Company’s work, provide feedback, and signoff
                    Approval in a timely Manner
                  </li>
                  <li>Make every effort to adhere to all agreed deadlines.</li>

                  <li>
                    Adhere to the payment schedule laid out in the agreement.
                  </li>
                  <li>
                    Provide a minimum of one months notice in writing, or by
                    email should you wish to Cancel any contract.
                  </li>
                </ul>
                <ul>
                  <h3>THE COMPANY AGREES TO</h3>
                </ul>
                <ul className="dots">
                  <li>
                    Carry out services in a professional and timely manner.
                  </li>
                  <li>
                    Make every effort to adhere to any deadlines agreed between
                    us and you.
                  </li>
                  <li>
                    Make a reasonable number of revisions to the design, layout,
                    colors’ etc, until you are satisfied with the design concept
                    or such time as both parties feel an agreement is likely to
                    be reached but no more than 2 major revisions. Additional
                    revisions or design work outside the scope of the project
                    will be charged separately.
                  </li>
                  <li>
                    Maintain up to date skills and knowledge through regular
                    training and research.
                  </li>
                </ul>
                <ul>
                  <h3>WEBSITE DESIGN</h3>
                </ul>
                <ul className="dots">
                  <li>
                    All websites are developed to work primarily across all
                    major browsers and platforms Including other devices such as
                    mobile phones and touchpad’s. However, the Company Cannot
                    guarantee complete and/or long term compatibility across
                    every major browser, Platform or handheld device due to
                    updates/upgrades by their respective vendors.
                  </li>
                  <li>
                    The Company cannot guarantee compatibility in old or
                    redundant browser software.
                  </li>
                  <li>
                    Database, Application and E-Commerce Development:
                    <ul>
                      <li style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                        o Aamanto Technologies cannot take responsibility for
                        any losses incurred by the use of any software created
                        for the client. Whilst every care has been taken to
                        ensure products are problem free and accurate, the
                        ultimate responsibility lies with the client in ensuring
                        that all software is functioning correctly before use.
                      </li>
                      <li style={{ paddingBottom: "10px" }}>
                        o The client is expected to provide or seek any
                        information, additional software, support or
                        co-operation pertaining to the server required in order
                        for the application to be correctly developed. Where
                        large applications are to be developed, it is the
                        client’s responsibility to provide a suitable testing
                        environment which is identical to the final production
                        environment.
                      </li>
                      <li>
                        o The client is expected to test fully any application
                        or programming relating to a site developed by Aamanto
                        Technologies before being made generally available for
                        use. Where "bugs", errors or other issues are found
                        after the site is live, Aamanto Technologies will
                        endeavor (but is not obliged to) to correct these issues
                        to meet the standards of function outlined in the brief.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <h3>PHOTOGRAPHS OR IMAGES</h3>
                </ul>
                <ul className="dots">
                  <li>
                    Any images or photographs that you supply should be in
                    digital format, usually no smaller than 1024x768 pixels,
                    with a suitable resolution that will allow them to be
                    resized and used on screen. Traditional paper photographs
                    requiring scanning are acceptable, however, there may be
                    additional costs incurred due to time spent scanning and
                    retouching the images. This depends entirely on the project
                    and the number of images involved.
                  </li>
                  <li>
                    Any images that the company are asked to obtain from third
                    party photographers or stock photography will be charged as
                    an additional cost.
                  </li>
                  <li>
                    The Client guarantees that any elements of text, graphics,
                    photos, designs, trademarks, or other artwork furnished to
                    the Company for inclusion in their website, or other design,
                    are owned by the Client, or that the Client has permission
                    from the rightful owner to use each of these elements, and
                    will hold harmless, protect, indemnify and defend the
                    Company and its subcontractors from any liability (including
                    solicitors fees and court costs), including any claim or
                    suit, threatened or actual, arising from the use of such
                    elements furnished by the Client.
                  </li>
                  <li>
                    Evidence of ownership or permissions may be requested by the
                    Company.
                  </li>
                </ul>
                <ul>
                  <h3>LIABILITY</h3>
                </ul>
                <ul className="dots">
                  <li>
                    The company will not be held liable for any missed launch
                    date or deadline, if the client has been late in supplying
                    materials, or has not approved or signed off work onetime,
                    at any stage.
                  </li>
                </ul>
                <ul>
                  <h3>CONFIDENTIALITY</h3>
                </ul>
                <ul className="dots">
                  <li>
                    The Company adheres to all national and EU data protection,
                    data transfer, data retention, and confidentiality
                    regulations and always stores data sent to us in a secure
                    manner within our security policy.
                  </li>
                </ul>
                <ul>
                  <h3>GENERAL</h3>
                </ul>
                <ul className="dots">
                  <li>
                    There may be an additional fee for any design changes
                    requested after the initial agreed design has been signed
                    off.
                  </li>
                  <li>
                    The Company is not responsible for writing or inputting any
                    text copy unless this has been specified by the client.
                  </li>
                  <li>
                    The Company cannot guarantee that the Website is fully
                    compatible with all hosting provider’s server operating
                    systems, especially any contact forms, database driven
                    websites, etc.
                  </li>
                  <li>
                    The company does not offer any technical support for any web
                    site hosting Company that you may choose.
                  </li>
                  <li>
                    The company cannot guarantee that the functions contained
                    within any web page (or Part of your website design), will
                    always be error free, and therefore the company will not be
                    liable in any way whatsoever to you for any third party
                    damages, including lost profits, lost savings, or other
                    incidental, consequential or special damages arising out of
                    the operation of or inability to operate this web site and
                    any other web pages, even if you have advised us of the
                    possibilities of such damages.
                  </li>
                  <li>
                    If any provision of this agreement shall be unlawful, void,
                    or for any reason unenforceable, then that provision shall
                    be deemed severable from this agreement and shall not affect
                    the validity and enforceability of any remaining provisions.
                    Just the same as a fine, you cannot transfer this contract
                    to anyone else without our consent or permission.
                  </li>
                  <li>
                    This contract remains in force and need not be renewed.
                  </li>
                  <li>
                    Although the company have tried to keep this contract
                    language simple, the intentions are serious, and the
                    contract is a legal document under the exclusive
                    jurisdiction of Law and Courts.
                  </li>
                </ul>
                <ul>
                  <h3>CHANGES TO THESE TERMS AND CONDITIONS</h3>
                  <b style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    Aamanto Technologies reserves the right to add, delete, or
                    modify any provision of these Terms and Conditions at any
                    time without notice. Failure to receive notification of a
                    change does not make those changes invalid.
                  </b>
                </ul>
                <ul>
                  <h3>PAYMENT FOR SERVICES</h3>
                </ul>
                <ul className="dots">
                  <li>
                    Payment: At the start of the development process, you need
                    to pay us 50% of the total development costs, the remaining
                    balance of 50% Will be given upon the completion of the
                    project. We will start the development immediately upon
                    receiving of the initial payment.
                  </li>
                  <li>
                    Payment Gateway: The client will have the option to pay
                    online or invoice based payment.
                  </li>
                  <li>
                    Service Charges: As per Canadian government regulations some
                    of the payment requires service tax. The client will be
                    informed by mail for such payments.
                  </li>
                </ul>
                <ul>
                  <h3>CANCELLATION & REFUND POLICY</h3>
                </ul>
                <ul className="dots">
                  <li>
                    <b>
                      Note*: There will not be any refund to client in case of
                      any cancellation of the services.
                    </b>
                  </li>
                  <li>
                    <b>
                      {" "}
                      In case if the work is not started after the payment the
                      client will get the 50% amount of refund for the
                      cancellation of the services.
                    </b>
                  </li>
                </ul>
                <ul>
                  <h3>DELIVERY TIMELINE</h3>
                </ul>
                <ul className="dots">
                  <li>
                    <b>
                      The delivery timeline will be stipulated in the agreed
                      proposal.
                    </b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TermsConditions
