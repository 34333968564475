import * as React from "react"
import Layout from "../components/layout"
import TermsConditions from "../components/Index/TermsConditions"
const terms = () => {
  return (
    <Layout>
      <TermsConditions />
    </Layout>
  )
}
export default terms
